import {
  Datagrid,
  List,
  NumberField,
  TextField,
  EditButton,
  ShowButton,
  ChipField,
  Pagination,
  useRecordContext,
} from 'react-admin';
import { MENU_ADMIN_LIST, TOURS_FIELDS, STATUS_TYPE} from '../../../shared/constants/const';

const PublicField = () => {
  const record = useRecordContext();
  return <span className='publicField'>{record.isPublished ? 'Опубликован' : 'Приостановлен'} </span>;
};

const TourMultiField = () => {
  const record = useRecordContext();
  const convertData = record.tourTypeMulti ? record.tourTypeMulti.split(';') : [];
  const res = convertData.map((el) => (
    <span key={el} className='publicField-small'>
      {el}
    </span>
  ));
  return res;
};

const StatusField = (props) => {
  const record = useRecordContext();
  const color =
    record.status === STATUS_TYPE.NEW ? 'default' : record.status === STATUS_TYPE.APPROVED ? 'success' : 'error';
  return <ChipField {...props} color={color} />;
};

const ToursList = () => {
  return (
      <List title={MENU_ADMIN_LIST.tours} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
      <Datagrid
        bulkActionButtons={false}
        sx={{
          '& .RaDatagrid-rowCell': { padding: '6px 5px' },
          '& .MuiTableHead-root .MuiTableCell-root:last-child': { display: 'none' },
        }}
      >
        <TextField source='title' sortable={false} label={TOURS_FIELDS.title} />
        <TourMultiField source='tourTypeMulti' sortable={false} label={TOURS_FIELDS.tourType} />
        <StatusField source='status' sortable={false} label={TOURS_FIELDS.status} />
        <PublicField source='isPublished' sortable={false} label={TOURS_FIELDS.statusPublic} />
        <NumberField source='price' sortable={false} label={TOURS_FIELDS.price} />
        <TextField source='operatorEmail' sortable={false} label={TOURS_FIELDS.operator} />
        <ShowButton />
        <EditButton label='Recommendations' />
      </Datagrid>
    </List>
  );
};

export default ToursList;
