import {
  CreateParams,
  DeleteManyParams,
  DeleteResult,
  fetchUtils,
  GetListParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
} from 'react-admin';
import { stringify } from 'querystring';
import { store } from '../store';
import { logout, refreshTokens } from 'manageStore/user/userSlice';
import { API_URL } from './commonApi';
import api from 'http/index';
import { transformListData } from '../helpers/admin/transformListData';
import { STATUS_TYPE, TRANSFORM_TEXT } from '../shared/constants/const';
import { handleError } from './handleError';
import {TBlog, TUser} from 'shared/types/types';
import { TDayTour } from 'shared/types/tours.types';
import { TSingleFile } from 'shared/types/common.types';
import { transformItemData } from 'helpers/admin/transformItemData';

const httpClient = fetchUtils.fetchJson;

const keysTransformData: Record<string, string> = {
  'tour-day-photos': 'tourDayPhotos',
  tours: 'tours',
};

const convertImg = async (
  data: { rawFile: File } | TSingleFile | ({ rawFile: File } | TSingleFile)[] | null,
  needArray = true
) => {
  if (!data) return null;
  if (!Array.isArray(data)) {
    if (!('rawFile' in data)) {
      return data;
    }
    data = [data];
  }
  const newPictures: { rawFile: File }[] = [];
  const oldPictures: TSingleFile[] = [];
  data.forEach((pic) => {
    'rawFile' in pic ? newPictures.push(pic) : oldPictures.push(pic);
  });
  if (!newPictures.length) {
    return needArray ? data : data[0];
  }
  const formData = new FormData();
  newPictures.forEach((pic) => formData.append('data', pic.rawFile));
  const savedPictures = await api(store.dispatch, logout, refreshTokens).post('file', formData);
  const pictures = oldPictures.concat(savedPictures.data);
  return needArray ? pictures : pictures[0];
};

const checkImg = async (data: any) => {
  if (data.preview) {
    const needArray = false;
    data.preview = await convertImg(data.preview, needArray);
  }
  if (data.photo) {
    const needArray = false;
    data.photo = await convertImg(data.photo, needArray);
  }
  if (data.files) {
    data.files = await convertImg(data.files);
  }
  if (data.uploadedFiles) {
    data.uploadedFiles = await convertImg(data.uploadedFiles);
  }
  if (data.habitationPhotosFiles) {
    data.habitationPhotosFiles = await convertImg(data.habitationPhotosFiles);
  }
  if (data.photos) {
    data.photos = await convertImg(data.photos);
  }
  if (data.days) {
    const needArray = false;
    const photoDaysRequests = data.days.map((day: TDayTour) => convertImg(day.photo, needArray));
    const photoDays = await Promise.all(photoDaysRequests);
    data.days.map((day: TDayTour, index: number) => (day.photo = photoDays[index]));
  }
  if (data.partnerPhotos) {
    data.partnerPhotos = await convertImg(data.partnerPhotos);
  }
  if (data.gallery) {
    data.gallery = await convertImg(data.gallery);
  }
  if (data.reasons?.length) {
    const needArray = false;
    const photoRequests = data.reasons.map((item: any) => convertImg(item.photo, needArray));
    const photo = await Promise.all(photoRequests);
    data.reasons.map((item: any, index: number) => (item.photo = photo[index]));
  }
  if (data.facts?.length) {
    const needArray = false;
    const photoFactsRequests = data.facts.map((item: any) => convertImg(item.photo, needArray));
    const photoFacts = await Promise.all(photoFactsRequests);
    data.facts.map((item: any, index: number) => (item.photo = photoFacts[index]));
  }
  if (data.factPhoto) {
    data.factPhoto = await convertImg(data.factPhoto, false);
  }

  if (data.faces?.length) {
    const needArray = false;
    const photoFacesRequests = data.faces.map((item: any) => convertImg(item.photo, needArray));
    const photoFaces = await Promise.all(photoFacesRequests);
    data.faces.map((item: any, index: number) => (item.photo = photoFaces[index]));
  }

};

const checkImgForBlog = async (data: any) => {
  if (data.preview) {
    const needArray = false;
    data.preview = await convertImg(data.preview, needArray);
  }
  if (data.photo) {
    const needArray = false;
    data.photo = await convertImg(data.photo, needArray);
  }
  if (data.galleryWithAuthor?.length) {
    const needArray = false;
    const photoFactsRequests = data.galleryWithAuthor.map((item: any) => convertImg(item.photo, needArray));
    const photoFacts = await Promise.all(photoFactsRequests);
    data.galleryWithAuthor.map((item: any, index: number) => (item.photo = photoFacts[index]));
  }
};


const adminApi = {
  async getList(resource: string, { pagination, filter: { role } }: GetListParams) {
    const { perPage, page } = pagination;
    const roles: typeof TRANSFORM_TEXT.role & Record<string, string> = TRANSFORM_TEXT.role;
    const methodTransformData = resource in keysTransformData ? keysTransformData[resource] : resource;
    if (resource === 'operator') {
      resource = 'operator/unapproved';
    }
    try {
      let { data } = await api(store.dispatch, logout, refreshTokens).get(resource);
      if (resource === 'user') {
        const filterRole = role ? roles[role] : roles.admin;
        data = data?.filter((user: TUser) => user.role === filterRole);
      }

      const total = data.length;
      const pageData = data.splice(perPage * (page - 1), perPage);
      return {
        data: transformListData[methodTransformData] ? transformListData[methodTransformData](pageData) : pageData,
        total,
      };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async getOne(resource: string, params: RaRecord): Promise<GetOneResult> {
    const { id } = params;
    if (id === 'new') return Promise.resolve({ data: { id } });
    const pathUrl = `${resource}/${id}`;
    try {
      const { data } = await api(store.dispatch, logout, refreshTokens).get(pathUrl);
      return {
        data: transformItemData[resource] ? transformItemData[resource](data) : data,
      };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async getMany(resource: string) {
    const pathUrl = resource;
    const methodTransformData = keysTransformData[resource] || resource;
    try {
      const { data } = await api(store.dispatch, logout, refreshTokens).get(pathUrl);
      return {
        data: transformListData[methodTransformData] ? transformListData[methodTransformData](data) : data,
      };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  getManyReference: (resource: string, params: RaRecord) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${API_URL}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers?.get('content-range')?.split('/').pop() || '', 10),
    }));
  },

  async update(resource: string, params: RaRecord) {
    const { data, id } = params;
    try {
      if(resource == 'blog') {
        checkBlock(data)
        await checkImgForBlog(data);
      } else{
        await checkImg(data);
      }
      const pathUrl = `${resource}/${id}`;
      const resp = await api(store.dispatch, logout, refreshTokens).put(pathUrl, data);
      return resp;
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  updateMany: (resource: string, params: UpdateManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  async create(resource: string, params: CreateParams) {
    let { data } = params;
    delete data.id;
    if (resource === 'user') {
      data.role = TRANSFORM_TEXT.role.admin;
      data.policy = true;
    }
    try {
      if (data.npa_doc) {
        resource = `${resource}?caption=${data.caption}`;
        const formData = new FormData();
        formData.append('data', data.npa_doc.rawFile);
        data = formData;
      }
      if(resource == 'blog') {
        checkBlock(data)
        await checkImgForBlog(data);
      } else{
        await checkImg(data);
      }
      const resp = await api(store.dispatch, logout, refreshTokens).post(resource, data);
      return resp;
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async delete(resource: string, params: RaRecord): Promise<DeleteResult> {
    const isNewMethod = resource === 'inddoc' || resource === 'structure';
    const pathUrl = isNewMethod ? `${resource}/${params.id}` : `${resource}?ids=${params.id}`;
    try {
      await api(store.dispatch, logout, refreshTokens).delete(pathUrl);
      return Promise.resolve({ data: params.id });
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async deleteMany(resource: string, params: DeleteManyParams) {
    const query = {
      ids: params.ids,
    };
    const pathUrl = `${resource}?${stringify(query)}`;
    try {
      await api(store.dispatch, logout, refreshTokens).delete(pathUrl);
      return { data: params.ids };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async confirmOperator(params: RaRecord) {
    const { id } = params;
    const pathUrl = `/operator/${id}/approve`;
    try {
      await api(store.dispatch, logout, refreshTokens).put(pathUrl);
      return { data: params.id };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async confirmTour(params: RaRecord) {
    const { id } = params;
    const pathUrl = `/tours/${id}/status`;
    try {
      await api(store.dispatch, logout, refreshTokens).post(pathUrl, {
        status: STATUS_TYPE.APPROVED,
      });
      const updateData = { ...params, isPublished: true };
      await this.update('tours', { id, data: updateData });
      return { data: params.id };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },

  async rejectTour(params: RaRecord) {
    const { id } = params;
    const pathUrl = `/tours/${id}/status`;
    try {
      await api(store.dispatch, logout, refreshTokens).post(pathUrl, {
        status: STATUS_TYPE.REJECTED,
      });
      return { data: id };
    } catch (e) {
      const message = handleError(e);
      return Promise.reject(message);
    }
  },
};

const checkBlock = (data:any) => {
  if(data.nextBlog && !data.nextBlog.id ) {
    data.nextBlog = null
  }
}

export default adminApi;

